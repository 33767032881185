import "./App.css";
import Category from "./components/category";
import Footer from "./components/footer";
import { useState, useEffect } from "react";
import axios from "axios";
import Panier from "./components/panier";
import SEO from "./components/seo";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
library.add(faPlusCircle, faMinusCircle, faTimesCircle, faStar);

function App() {
  // set initial state
  const [data, getRestaurant] = useState();
  const [cart, updateCart] = useState([]);
  const [isLoading, setLoading] = useState(true);

  //Fetch restaurant date using Effect Hook..
  useEffect(() => {
    // Do an axios request by an IIFE
    (async () => {
      const newData = await axios(
        `${process.env.REACT_APP_API}/restaurant`
      );
      getRestaurant(newData.data);
      setLoading(false);
    })();
  }, []);

  // Render
  return isLoading ? (
    <div>Fetching data</div>
  ) : (
    <div className=''>
      <SEO
        title='Deliveroo by Masood: Home'
        page='Home'
        description='Deliveroo project by Masood @ LE REACTEUR, PARIS'
        keywords={`Deliveroo, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://deliveroo.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://deliveroo.cloudi.fr/logo192.png'
      />
      <div className='nav'>
        <div className='container'>
          <img
            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmnYNATlbZJHnbExscwRVlsRadQwz5WrB4WuvkQ73Ywz1n2cY1b23XUVFRoBiEqokkqSc&usqp=CAU'
            alt=''
          />
        </div>
      </div>
      <div className='header'>
        <div className='restaurant container'>
          <div className='restaurant-title'>
            <h1>{data?.restaurant?.name}</h1>
            <p className='title-para'>{data?.restaurant?.description}</p>
          </div>
          <div className='title-img'>
            <img className='title-img' src={data?.restaurant?.picture} alt='' />
          </div>
        </div>
      </div>

      <div className='category container'>
        <Category
          cart={cart}
          updateCart={updateCart}
          category={data?.categories}
        />
        <div className='cart-pan'>
          <Panier cart={cart} updateCart={updateCart} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
