import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Meal = ({ meals, cart, updateCart }) => {
  const addToCart = (meal) => {
    const newCart = [...cart];
    const found = newCart.find((repas) => repas.id === meal.id);
    if (found) {
      found.quantity++;
    } else {
      meal.quantity = 1;
      newCart.push(meal);
    }
    updateCart(newCart);
  };

  return (
    <div className="meals">
      {meals.map((meal, index) => {
        return (
          <div onClick={() => addToCart(meal)} className="meal" key={meal.id}>
            <div>
              <h3>{meal.title}</h3>
              <p className="meal-info">{meal.description}</p>
              <span className="price">{meal.price} € </span>
              {meal.popular ? (
                <FontAwesomeIcon className="pop" icon="star" />
              ) : (
                ""
              )}
            </div>
            <div>
              <img
                className={`mealpic-lg ${!meal.picture ? "hide-thumb" : ""}`}
                src={meal.picture}
                alt=""
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Meal;
