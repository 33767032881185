import Meal from "./meal";

const Category = ({ cart, updateCart, category }) => {
  return (
    <div>
      {category.map((elem, index) => {
        if (elem.meals.length > 0) {
          return (
            <div key={index}>
              <h2>{elem.name}</h2>
              <Meal meals={elem.meals} cart={cart} updateCart={updateCart} />
            </div>
          );
        }
      })}
    </div>
  );
};

export default Category;
