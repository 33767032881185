const EmptyCart = () => {
  return (
    <div className="cart empty-cart">
      <div>
        <h3>Valider mon panier</h3>
      </div>
      <div>
        <p>Votre panier est vide.</p>
      </div>
    </div>
  );
};

export default EmptyCart;
