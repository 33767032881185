import Counter from "./counter";
import EmptyCart from "./emptycart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const Panier = ({ cart, updateCart }) => {
  const [display, setDisplay] = useState(true);
  const [displayModal, setModalDisplay] = useState(false);

  //Delivery charges;
  const deliveryCharges = 5.0;

  //Calculate cart SubTotal
  const calcSubTotal = () => {
    let total = 0;
    cart.map((item) => {
      total += item.price * item.quantity;
    });
    return total.toFixed(2);
  };

  //Calculate SubTotal
  const calcTotal = () => {
    let total = 0;
    cart.map((item) => {
      total += item.price * item.quantity;
    });
    total += deliveryCharges;
    return total.toFixed(2);
  };

  //Calculate Cart Items
  const cartItems = () => {
    let telly = 0;
    cart.forEach((repas) => (telly += repas.quantity));
    return telly;
  };

  return !cart.length ? (
    <EmptyCart />
  ) : (
    <div className="cart">
      <div>
        <h3 onClick={() => setModalDisplay(true)} className="cart-label">
          Valider mon panier
        </h3>
        <div className="cart-label-sm" onClick={() => setDisplay(!display)}>
          <p>{cartItems()}</p>
          <h5>Voir le panier</h5>
          <h5>{calcTotal()} €</h5>
        </div>
      </div>
      <div className={`items-pan ${!display ? "hide-cart" : ""}`}>
        <div className="cart-label-x">
          <FontAwesomeIcon
            onClick={() => setDisplay(false)}
            icon="times-circle"
          />
        </div>
        {cart.map((item, index) => {
          return (
            <div className="cart-meal" key={index}>
              <div cla>
                <Counter index={index} cart={cart} updateCart={updateCart} />
                <span className="meal-name">{item.title}</span>
              </div>
              <div>
                <p>{(item.price * item.quantity).toFixed(2)} €</p>
              </div>
            </div>
          );
        })}
      </div>

      <div className={`sous-total ${!display ? "hide-cart" : ""}`}>
        <span>Sous-total</span>
        <span>{calcSubTotal()} €</span>
      </div>
      <div className="hide-cart">
        <span className={!display ? "hide-cart" : ""}>Frais de livraison</span>
        <span className={!display ? "hide-cart" : ""}>{deliveryCharges} €</span>
      </div>
      <div className={`total ${!display ? "hide-cart" : ""}`}>
        <h3>Total</h3>
        <h3>{calcTotal()} €</h3>
      </div>
    </div>
  );
};

export default Panier;
