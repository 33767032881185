import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Counter = ({ index, cart, updateCart }) => {
  //Remove an itme for the basket..
  const removeItem = () => {
    if (cart[index].quantity <= 1) {
      const newCart = [...cart];
      newCart.splice(index, 1);
      updateCart(newCart);
    } else {
      const newCart = [...cart];
      newCart[index].quantity--;
      updateCart(newCart);
    }
  };

  // Icrement item quantity in the cart
  const addItem = () => {
    if (cart[index].quantity < 20) {
      const newCart = [...cart];
      newCart[index].quantity++;
      updateCart(newCart);
    } else {
      alert(
        "Item quantity exceeds limit. You can't order more than twenty items."
      );
    }
  };

  return (
    
    <span className="counter" key={index}>
      <FontAwesomeIcon onClick={removeItem} icon="minus-circle" />
      {/* <span className="counter" onClick={removeItem}>
        -
      </span> */}
      <span>{cart[index].quantity}</span>
      {/* <span className="counter" onClick={addItem}>
        +
      </span> */}
      <FontAwesomeIcon onClick={addItem} icon="plus-circle" />
    </span>
  );
};

export default Counter;
